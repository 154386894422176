import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { DataGrid } from "@mui/x-data-grid";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import SendIcon from "@mui/icons-material/Send";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import ExploreIcon from '@mui/icons-material/Explore';
import { unstable_batchedUpdates } from "react-dom";

const kitTheme = createTheme({
  palette: {
    primary: {
      main: "#32a189",
      light: "#b3dad4",
    },
  },
});

let endPoint = "https://campustag-srv.iti.kit.edu"
// http://localhost:5000";
let socket = io.connect(`${endPoint}`);

//////////////////////////////////////////////////////////////////////
// Helper functions

// Get parameters from the url.
function getURLParam(name) {
  const query = new URLSearchParams(window.location.search);
  return query.get(name);
};


// Check whether two dictionaries contain the same content, JS style...
function dictionariesAreEqual(dict1, dict2) {
  return JSON.stringify(dict1) === JSON.stringify(dict2);
}

//////////////////////////////////////////////////////////////////////
// The actual app.
export default function App() {
  // Session information.
  const sessionID = getURLParam("session");
  const clientID  = parseInt(getURLParam("id"));

  
  //////////////////////////////////////////////////////////////////////
  // State that needs to be synced with the server.

  // Name of the station the user is playing.
  const [userName, setUserName] = useState(null);

  // Status of the explore buttons.
  const [exploreButtons, setExploreButtons] = useState(false);

  // The adjacency information.
  const [adjacencies, setAdjacencies] = useState([]);

  // The values that are shared between users.  The content of this
  // array always reflects the status on the server.
  const [values, setValues] = useState([]);


  //////////////////////////////////////////////////////////////////////
  // Local state that is not synced to the server.

  // A local copy of the values that can be edited.
  const [editValues, setEditValues] = useState([]);

  // Whether the user is currently editing data (in which case the
  // buttons should be disabled).
  const [currentlyEditing, setCurrentlyEditing] = useState(false);

  // Error handling for bad sessionID or no userName.
  const [error, setError] = useState(true);

  
  //////////////////////////////////////////////////////////////////////
  // Sync with server.

  useEffect(() => {
    // Ask the server for the session data.
    socket.emit(
      "client_status_request",
      { data: { sessionID: sessionID } },
      (data) => {
        // Wrong sessionID -> no data -> return.
        if (!data) {
          setError("wrongSessionID");
          return;
        }

        // Handle the received data.
        unstable_batchedUpdates(() => {
          gotGraphUpdate(data);
          gotValuesUpdate(data);
          gotExploreButtonsUpdate(data);
        });

        // Listen to future graph updates.
        socket.on("adjacencies", gotGraphUpdate);

        // Listen to future value changes.
        socket.on("values", gotValuesUpdate);

        // Listen to future explore button (de)activation.
        socket.on("explore_buttons", gotExploreButtonsUpdate);
      }
    );
  }, []);

  // Deal with a new graph.
  const gotGraphUpdate = (graphData) => {
    const userName = graphData["userNames"][clientID];
    unstable_batchedUpdates(() => {
      setError(!userName ? "noUserName" : false);
      setUserName(userName);
      setAdjacencies(graphData["adjacencies"][clientID] || []);
    });
  };

  // Deal with updated values.
  const gotValuesUpdate = (valuesData) => {
    unstable_batchedUpdates(() => {
      setValues(valuesData["values"]);
      setEditValues(valuesData["values"]);
    });
  };

  // Deal with (de)activating the explore buttons.
  const gotExploreButtonsUpdate = (exploreButtonData) => {
    setExploreButtons(exploreButtonData["exploreButtons"])
  }

  // Deal with marked node update.  This needs special treatment as
  // the update function depends on the edit values.
  useEffect(() => {
    // Listen to updates on marked nodes.
    socket.on("edited_mark_changed", gotMarkedNodeUpdate);
    // Unlisten before editValues changes.
    return () => socket.off("edited_mark_changed", gotMarkedNodeUpdate);
  }, [editValues, values]);
  const gotMarkedNodeUpdate = (data) => {
    const vals = values;
    vals[data["nodeID"]]["has_edited"] = data["newValue"];
    setValues(vals);
    const eVals = [...editValues];
    eVals[data["nodeID"]]["has_edited"] = data["newValue"];
    setEditValues(eVals);
  }

  // Send values to the server.
  const sendEditsToServer = () => {
    socket.emit("values_change", {
      data: { sessionID: sessionID, clientID: clientID, values: editValues },
    });
  };
    

  //////////////////////////////////////////////////////////////////////
  // Explore button logic.

  // Get a copy of the row of a node.
  const getValueRowCopy = (nodeName) => {
    for (let row of values) {
      if (row.node == nodeName) {
        return {...row};
      }
    }
    // This should never happen.
    console.log(`Error: Unable to find row with name ${nodeName}.`)
  };

  // Handle the exploration of a node.
  const exploreAdjacency = (adj) => {
    const targetRow = getValueRowCopy(adj.node);
    const sourceValue = parseInt(getValueRowCopy(userName).value1);
    targetRow.value1 = (sourceValue + adj.distance).toString();
    changeValueRow(targetRow);
  }


  //////////////////////////////////////////////////////////////////////
  // Table for the adjacency information.

  // Rendering the distance including explore button.
  const renderDistanceCell = (params) => {
    if (!exploreButtons) {
      // Just the value.
      return params.value;
    } 
    // Value with explore button.
    return (
      <span>
        <span style={{display: "inline-block", minWidth: "3ch"}}>{params.value}</span>
        <IconButton onClick={() => exploreAdjacency(params.row)} color="primary">
          <ExploreIcon fontSize="large" />
        </IconButton>
      </span>
    );
  };

  // Schema of the table showing the adjacency information.
  const adjacencyColumns = [
    { field: "node", headerName: "Haltestelle", editable: false, width: 200},
    { field: "distance", headerName: "Distanz", editable: false, 
      renderCell: renderDistanceCell }
  ];

  
  //////////////////////////////////////////////////////////////////////
  // Value table editing logic.

  // Change the value of a row locally.
  const changeValueRow = async (newRow) => {
    setEditValues(editValues.map((oldRow) => 
      oldRow.id == newRow.id ? newRow : oldRow));
    return newRow;
  };


  //////////////////////////////////////////////////////////////////////
  // Value Table visuals.

  // Mark nodes that are marked as being edited/visited.
  const nodeCellStyle = (params) => {
    if (values[params.id - 1]["has_edited"]) {
      return `super-app-theme--EditedByOther`;
    }
  };

  // Mark values that the user has locally edited but not yet submitted.
  const valueCellStyle = (params) => {
    if (values[params.id - 1]["value1"] !=
      editValues[params.id - 1]["value1"]) {
      return "super-app-theme--Edited";
    }
  };

  // Render value cell showing the difference to server value.
  const renderValueCell = (params) => {
    const prevValue = values[params.id - 1].value1;
    if (prevValue == params.value || prevValue == "") {
      return params.value;
    } else {
      return <span>
        <span style={{
          textDecoration: "line-through",
          textDecorationThickness: "2px",
          textDecorationColor: "#a22223"
        }}>&nbsp;{prevValue}&nbsp;</span>
        <span> → {params.value}</span>
      </span>;
    }
  };

  // The schema of the table showing the editable values.
  const valueColumns = [
    { field: "node", headerName: "Haltestelle", editable: false, width: 200, 
      cellClassName: nodeCellStyle },
    { field: "value1", headerName: "Wert", editable: true,
      cellClassName: valueCellStyle, renderCell: renderValueCell },
  ];


  //////////////////////////////////////////////////////////////////////
  // Some style stuff.

  // Headlines.
  const headlineStyle = {
    fontSize: 25,
    textAlign: "center",
  };

  // Rows should have alternating background colors.
  const colorRowsEvenOdd = (params) => {
    if (params.row.id % 2 === 0) {
      return `super-app-theme--Even`;
    }
    return `super-app-theme--Default`;
  }

  // The row of the user should be highlighted (even/odd otherwise).
  const highlightSelfRow = (params) => {
    if (params.row.id > values.length) return;
    if (values[params.row.id - 1]["node"] == userName) {
      return `super-app-theme--Self`;
    }
    return colorRowsEvenOdd(params);
  };

  // Scroll trigger (must evaluated before returning anything).
  const isScrolledDown = useScrollTrigger();

  //////////////////////////////////////////////////////////////////////
  // Error handling.

  if (error) {
    return (
      <React.Fragment>
        <CssBaseline />
        <ThemeProvider theme={kitTheme}>
          <Slide appear={false} direction="down" in={!isScrolledDown}>
            <AppBar>
              <Toolbar>
                <Typography variant="h6" component="div">
                  Algorithmische Pfadfinder
                </Typography>
              </Toolbar>
            </AppBar>
          </Slide>
          <Toolbar />
          <Container>
            <Box sx={{my: 2}}>
              {(error == "noUserName") &&
               <Stack spacing={2}>
                 <div style={headlineStyle}>Du bist keine Haltestelle</div>
                 <div>
                   Das liegt vermutlich daran, dass das Schienennetz gerade noch gebaut wird.  
                   Falls diese Nachricht nicht gleich verschwindet, dann frag am besten mal nach, was da los ist.
                 </div>
               </Stack>
              }
              {(error == "wrongSessionID") &&
               <Stack spacing={2}>
                 <div style={headlineStyle}>Falsche Session ID</div>
                 <div>
                   Schau mal nach ob du die Session-Nummer richtig abgetippt hast.  
                   Falls ja, dann frag am besten mal nach, was da los ist.
                 </div>
               </Stack>
              }
            </Box>
          </Container>
        </ThemeProvider>
      </React.Fragment>
    );
  }

  //////////////////////////////////////////////////////////////////////
  // Page description.
  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={kitTheme}>
        <Slide appear={false} direction="down" in={!isScrolledDown}>
          <AppBar>
            <Toolbar>
              <Typography variant="h6" component="div">
                Algorithmische Pfadfinder
              </Typography>
            </Toolbar>
          </AppBar>
        </Slide>
        <Toolbar />
        <Container>
          <Box
            sx={{
              my: 2,
              "& .super-app-theme--Edited": {
                bgcolor: (theme) => theme.palette.primary.light,
              },
              "& .super-app-theme--Default": {
                bgcolor: () => "#ffffff",
              },
              "& .super-app-theme--Even": {
                bgcolor: () => "#f9f9f9",
              },
              "& .super-app-theme--Self": {
                bgcolor: () => "#c2cae0",
              },
              "& .super-app-theme--EditedByOther": {
                bgcolor: () => "#fdf6b0",
              },
            }}
          >
            <Stack spacing={2}>
              <div style={headlineStyle}>Du bist Haltestelle {userName}</div>
              <div>Du kennst folgende Distanzen zu anderen Haltestellen.</div>
              <DataGrid
                autoHeight
                hideFooter
                rows={adjacencies}
                columns={adjacencyColumns}
                // pageSize={5}
                // rowsPerPageOptions={[5]}
                disableSelectionOnClick
                getRowClassName={colorRowsEvenOdd}
              />
              <div style={headlineStyle}>Hier kannst du Werte teilen</div>
              <DataGrid
                experimentalFeatures={{ newEditingApi: true }}
                autoHeight
                hideFooter
                rows={editValues}
                columns={valueColumns}
                // pageSize={5}
                // rowsPerPageOptions={[5]}
                disableSelectionOnClick
                processRowUpdate={changeValueRow}
                getRowClassName={highlightSelfRow}
                onCellEditStart = {() => setCurrentlyEditing(true)}
                onCellEditStop = {() => setCurrentlyEditing(false)}
              />
              {/*Buttons*/}
              {
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-evenly"
                >
                  <Button
                    variant="outlined"
                    disabled={currentlyEditing || dictionariesAreEqual(values, editValues)}
                    startIcon={<RestorePageIcon />}
                    color="error"
                    onClick={() => setEditValues(values)}
                  >
                    Zurücksetzen
                  </Button>
                  <Button
                    variant="contained"
                    disabled={currentlyEditing}
                    endIcon={<SendIcon />}
                    onClick={sendEditsToServer}
                  >
                    Senden
                  </Button>
                </Stack>
              }
              <ol>
                {!exploreButtons && 
                  <>
                    <li>Tippe doppelt auf eine Zelle, um einen Wert zu ändern.</li>
                    <li>
                      Tippe außerhalb der bearbeiteten Zelle, um die Änderung
                      einzuloggen. (Bearbeitete Zellen sind{" "}
                      <font color="#32a189">grün</font>.)
                    </li>
                  </>
                }
                {exploreButtons && 
                  <>
                    <li>
                      Tippe auf den Kompass{" "}
                      <span style={{color: "#32a189", verticalAlign: "text-top"}} >
                        <ExploreIcon  fontSize="small"/>
                      </span>{" "}
                      um die enstprechende Verbindung zu explorieren.
                    </li>
                  </>
                }
                <li>
                  Mit <font color="error">"Zurücksetzen"</font> können
                  eingeloggte Änderungen rückgängig gemacht werden.
                </li>
                <li>
                  Tippe <font color="#32a189">"Senden"</font>, um die
                  eingeloggten Änderungen mit den anderen zu teilen.
                </li>
              </ol>
            </Stack>
          </Box>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
}
